<template>
	<div>
	
		
		<div class="item_box x_start_v"><div>登录名称：</div><div>{{t_login_phone}}</div></div>
		<div class="item_box x_start_v"><div>直推用户推广金币充值分成：</div><div>{{t_user_gold_percent}}%</div></div>
		<div class="item_box x_start_v"><div>直推用户推广VIP充值分成：</div><div>{{t_user_vip_percent}}%</div></div>
		<div class="item_box x_start_v"><div>主播推广用户推广金币充值分成：</div><div>{{t_anchor_to_user_gold_percent}}%</div></div>
		
		<div class="item_box x_start_v"><div>主播推广用户推广VIP充值分成：</div><div>{{t_anchor_to_user_vip_percent}}%</div></div>
		<div class="item_box x_start_v"><div>cps代理名称：</div><div>{{t_channel_name}}</div></div>
		<div class="item_box x_start_v"><div>支付宝账号：</div><div>{{t_zhifubao_number}}</div></div>
		<div class="item_box x_start_v"><div>支付宝姓名：</div><div>{{t_zhifubao_name}}</div></div>
		<div class="item_box x_start_v"><div>CPS联系电话：</div><div>{{t_cps_phone}}</div></div>

		<div class="item_box x_start_v">
			<div>渠道包下载地址：</div>
			<div>{{t_dow_url}}</div>
		</div>
		
	
	</div>
</template>

<script>
	export default{
		data(){
			return{
				t_login_phone:null,  //  登陆名称
				t_user_gold_percent:null,  // 直推用户推广金币充值分成
				t_user_vip_percent:null,  // 直推用户推广VIP充值分成
				t_anchor_to_user_gold_percent:null,  // 主播推广用户推广金币充值分成
				t_anchor_to_user_vip_percent:null,  // 主播推广用户推广VIP充值分成
				t_channel_name:null,  // 渠道代理名称
				t_zhifubao_number:null,  // 支付宝账号
				t_zhifubao_name:null,  // 支付宝姓名
				t_cps_phone:null,  // CPS联系电话
				t_dow_url:null,  // CPS联系电话
			}
		},
		mounted() {
			this.getList()
		},
		methods:{
			async getList(){
				let that = this
				let param = {
					url: "myCpsInfo",
					data: null
				}
				this.loadingShow = true
				await this.api(param).then(res => {
					if(res.status == 0){
						that.t_login_phone = res.data.t_login_phone
						that.t_user_gold_percent = res.data.t_user_gold_percent
						that.t_user_vip_percent = res.data.t_user_vip_percent
						that.t_anchor_to_user_gold_percent = res.data.t_anchor_to_user_gold_percent
						that.t_anchor_to_user_vip_percent = res.data.t_anchor_to_user_vip_percent
						that.t_channel_name = res.data.t_channel_name
						that.t_zhifubao_number = res.data.t_zhifubao_number
						that.t_zhifubao_name = res.data.t_zhifubao_name
						that.t_cps_phone = res.data.t_cps_phone
						that.t_dow_url = res.data.t_dow_url
						
					}
				})
			},
		}
	}
</script>

<style scoped="">
	.item_box{
		margin-bottom: 20px;
	}
	.item_box>div:nth-of-type(1){
		width: 250px;
		text-align: right;
	}
	.item_box>div:nth-of-type(2){
		text-align: left;
		margin-left: 20px;
	}
</style>
